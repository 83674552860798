import React, { useEffect, useState } from "react";
import { graphql } from "gatsby"

import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ReleaseNotesTable } from "../components/releaseNotesTable";
import { getReleaseNotes } from "../utils/customApiCall";

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import "./releaseNotes.css"

const ReleaseNotesContent = ({ data }) => {
  let posts;
  if (data) {
    posts = data.allReleaseNotes.nodes
  }

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
              getReleaseNotes(response.accessToken).then(response => setGraphData(response.sort((a,b) => {
                return new Date(a.createdTime).getTime() - 
                    new Date(b.createdTime).getTime()
            })));
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
        <AuthenticatedTemplate>
          { graphData ? <ReleaseNotesTable releaseNotes={graphData} /> : null }
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ReleaseNotesTable releaseNotes={posts} />  
        </UnauthenticatedTemplate>
      </Paper>
  );
};

const ReleaseNotesPage = ({ data }) => (
  <Layout>
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Seo title="Release Notes" />
      <Typography gutterBottom variant="h2" component="h2" id="releaseNotesPageTitle">
        Release Notes
      </Typography>
      <ReleaseNotesContent data={data} />
    </Container>
  </Layout>
)

export default ReleaseNotesPage

export const pageQuery = graphql`
  query {
    allReleaseNotes(
      sort: { fields: [Timestamp], order: ASC }
      limit: 1000
    ) {
      nodes {
        id
        Title
        RowKey
        Body
        BugIDs
        EnhancementIDs
        Iteration
        Timestamp
      }
    }
  }  
`
