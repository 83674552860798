import * as React from "react"
import { Link } from "gatsby"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ReleaseNotesTable = (props) => {
    console.log(props.releaseNotes);

    return (
        <Table aria-label="simple table">
            <TableHead>
          <TableRow>
            {/* <TableCell align="center">ID</TableCell> */}
            <TableCell align="center">Title</TableCell>
            {/* <TableCell align="center">Iteration</TableCell> */}
            <TableCell align="left">Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.releaseNotes.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell align="right">{row.id}</TableCell> */}
              <TableCell align="center">{row.Title ? <Link to={`/release/${row.RowKey}`}>{row.Title}</Link> : 
                row.title ? <Link to={`/release/${row.id}`}>{row.title}</Link> : <Link to={`/release/${row.RowKey}`}>(Blank)</Link>}</TableCell>
              {/* <TableCell align="right">{row.iteration}</TableCell> */}
              <TableCell align="left">{ row.Timestamp ? new Date(row.Timestamp).toLocaleString() : new Date(`${row.createdTime}Z`).toLocaleString() }</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
    );
};
